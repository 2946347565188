import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    iconColor: 'white',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
  });

document.addEventListener("DOMContentLoaded", function() {
  const flashMessage = document.body.getAttribute("data-flash-notice");
  if (flashMessage) {
    Toast.fire({
      icon: 'success',
      title: flashMessage,
    });
  }
});
